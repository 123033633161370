<template>
  <!-- 该页面涉及：往返票，多家服务商比较 改动次数较多，略有一些复杂
          去程票this.coupons[0]
                已选去程票this.coupons[0][this.chosenCoupon[0]]
          返程票this.coupons[1]
                已选返程票this.coupons[1][this.chosenCoupon[1]] 
  注意深浅拷贝-->
  <div class="ticket">
    <!-- 第一部分-机票 -->
    <header class="head-background">
      {{aircraftOrderInfo.from}}
      <span class="icon iconfont icon-wangfandijia"></span>
      {{aircraftOrderInfo.to}}
    </header>
    <div v-for="(ticket,index) in aircraftTicket" :key="index">
      <div class="headInfo">
        <div class="line1">
          <van-tag class="tag" type="primary">{{index==0?'去程':'返程'}}</van-tag>
          <Logo :corp="coupons[index][chosenCoupon[index]].corp" class="tag" />
          {{ticket[0].airlineCompany}}&nbsp;|&nbsp;
          {{ticket[0].flightModel}}&nbsp;|&nbsp;
          {{ticket[0].departDate}}
        </div>
        <van-row type="flex" justify="space-between" class="line2">
          <van-col>
            <h3>{{ticket[0].departTime}}</h3>
          </van-col>
          <van-col>
            <h3>
              <span class="icon iconfont icon-icon_jipiaodancheng"></span>
            </h3>
          </van-col>
          <van-col>
            <h3>{{ticket[0].arriveTime}}</h3>
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" class="line1">
          <van-col span="12" class="van-ellipsis left">{{ticket[0].departAirport}}</van-col>
          <van-col class="cen">{{ticket[0].flightNo}}</van-col>
          <van-col
            span="12"
            class="van-ellipsis right"
            style="text-align:right"
          >{{ticket[0].arriveAirport}}</van-col>
        </van-row>
      </div>
      <van-panel>
        <template #header>
          <div style="padding:0 1rem">
            <van-row type="flex" justify="space-between">
              <van-col @click="listindex=index;showList=true;" class="blue">
                <p class="dropdown">
                  {{coupons[index][chosenCoupon[index]].seatClass}}(￥{{coupons[index][chosenCoupon[index]].value}})
                  <van-icon class="dropdownIcon" name="play" />
                  <span
                    :class="'surplus '+(coupons[index][chosenCoupon[index]].number<0||coupons[index][chosenCoupon[index]].number>=result.length?'success':'red')"
                  >余票:{{coupons[index][chosenCoupon[index]].number|surplusTicket}}</span>
                </p>
              </van-col>
              <van-col>
                <p class="dropdown" @click="fetchRule(index)">
                  <van-button
                    :loading="btnLoading[index]"
                    type="info"
                    plain
                    size="mini"
                    class="ruleBtn"
                  >更多规则</van-button>
                </p>
              </van-col>
            </van-row>
          </div>
        </template>
      </van-panel>
      <!-- 保险 -->
      <Insurance
        v-if="switch_insurance&&coupons[index][chosenCoupon[index]]"
        :updateInsuranceResult.sync="insuranceResult[index]"
        :corp="coupons[index][chosenCoupon[index]].corp"
        :hasLayout="true"
      />
      <div class="gap"></div>
    </div>

    <!-- 第二部分-选人 -->
    <van-panel title="出行人员">
      <PeoList
        :updateResult.sync="result"
        :seat="[coupons[0][chosenCoupon[0]],coupons[1][chosenCoupon[1]]]"
        :max="max"
        :compliant="compliant"
        :explanationText="explanationText"
        @explanFinish="explanFinish"
      />
    </van-panel>
    <div class="gap"></div>
    <!-- 公务票 -->
    <BusinessTicket
      v-if="config_businessticket"
      :toList="coupons[0]"
      :toIndex="chosenCoupon[0]"
      :backList="coupons[1]"
      :backIndex="chosenCoupon[1]"
    />

    <!-- 座次类型弹出层 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      class="pick-ticket"
      @opened="resetPosition"
    >
      <AircraftTicket
        :coupons="coupons[listindex]"
        :chosen-coupon="chosenCoupon[listindex]"
        :showList="showList"
        @change="onChange"
      />
    </van-popup>

    <!-- 退改规则弹窗 -->
    <van-dialog v-model="showRule" closeOnClickOverlay>
      <div class="rule">
        <table>
          <tr class="head">
            <td>时间点</td>
            <td>退票费</td>
            <td>同舱改期费</td>
            <td>签转</td>
          </tr>
          <tr v-for="(item,i) in ruleList.rulePointList" :key="i">
            <td>{{item.content}}</td>
            <td>￥{{item.refundFee}}/人</td>
            <td>￥{{item.modifyFee}}/人</td>
            <td>{{item.modifyStipulate}}</td>
          </tr>
        </table>
        <hr style="border-color:#fff" />
        <p>
          <strong>托运规则：</strong>
          {{ruleList.baggage}}
        </p>
      </div>
    </van-dialog>

    <div class="gap"></div>
    
    <!-- 第三部分-申请单信息 -->
    <BodyList :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>
    <!-- 第四部分 -->
    <van-submit-bar button-type="info" :price="totalPrice*100" button-text="提交占座" @submit="onSubmit">
      <template #tip>
        <div
          style="text-align:right"
        >（票价{{coupons[0][chosenCoupon[0]].value}}+{{coupons[1][chosenCoupon[1]].value}}/人+机建50*2/人{{(0 in insuranceResult[0]||0 in insuranceResult[1])?`+保险${insurancePriceGo}+${insurancePriceBack}/人`:''}}{{coupons[0][chosenCoupon[0]].price.ordernowReturn>0?'- (优惠'+coupons[0][chosenCoupon[0]].price.ordernowReturn+(coupons[1][chosenCoupon[1]].price.ordernowReturn>0?'+'+coupons[1][chosenCoupon[1]].price.ordernowReturn:'')+'/人)':''}}）*{{result.length}}人</div>
      </template>
    </van-submit-bar>

    <!-- 遮罩层动画 -->
    <LoadingAnimate v-if="wrapper" :text="'占座中'" />

    <LoadingCircle :show="wrapper1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingAnimate from "@/components/LoadingAnimate";
import LoadingCircle from "@/components/LoadingCircle";
import { checkSeat, CreateTrainPeoList } from "@/services/user";
import {
  AircraftServiceProvider,
  NeedAircraftInsurance,
  BusinessTicketConfig,
  GoWhere
} from "@/common/config";
import { createOrder, updataTicketDom, checkPrice } from "@/services/aircraft";
import Logo from "@/components/Logo";
import PeoList from "./components/PeoList";
import BodyList from "@/components/InfoList";
import BusinessTicket from "./components/BusinessTicket";
import AircraftTicket from "@/components/AircraftTicket";
import { getFlightModifyAndRefundRules } from "@/api/aircraft";
import Insurance from "@/components/Insurance";

export default {
  computed: {
    ...mapGetters([
      "aircraftTicket",
      "travelInfo",
      "aircraftOrderInfo",
      "personList",
    ]),
    // 去程保险价格
    insurancePriceGo: function () {
      let price = 0;
      this.insuranceResult[0].forEach((element) => {
        price += element.salePrice;
      });
      return price;
    },
    // 返程保险价格
    insurancePriceBack: function () {
      let price = 0;
      this.insuranceResult[1].forEach((element) => {
        price += element.salePrice;
      });
      return price;
    },
    // 总票价
    totalPrice: function () {
      return (
        (parseFloat(this.coupons[0][this.chosenCoupon[0]].value)-parseFloat(this.coupons[0][this.chosenCoupon[0]].price.ordernowReturn) + // 去程票价
          parseFloat(this.coupons[1][this.chosenCoupon[1]].value))-parseFloat(this.coupons[1][this.chosenCoupon[1]].price.ordernowReturn) * // 返程票价
          this.result.length +
        this.result.length * 100 + // *人数 + 人数*机建费*2
        (this.insurancePriceGo + this.insurancePriceBack) * this.result.length // 保险总价
      );
    },
  },
  activated() {
    window.setTitle("往返机票");
    this.$forceUpdate();
    this.compliant = false;
    this.wrapper = false;
    this.showList = false;
    // 修改最大人数
    let temp = Math.min(
      this.coupons[0][this.chosenCoupon[0]].number,
      this.coupons[1][this.chosenCoupon[1]].number
    );
    this.max = temp > 0 ? temp : 10;
    this.getSeatList();
  },
  created() {
    this.getSeatList();
  },
  data() {
    return {
      config_gowhere: GoWhere, // 下单成功跳转到哪儿
      config_businessticket: BusinessTicketConfig, // 公务票开关
      switch_insurance: NeedAircraftInsurance, // 保险功能开关
      allCorp: AircraftServiceProvider,
      max: 10,
      showPicker: false,
      insuranceResult: [[], []], // 已选保险,0去程 1返程
      listindex: 0, // 票面索引 --- ①
      btnLoading: [false, false], // 规则按钮加载状态开关
      showRule: false, // 退改弹窗开关
      ruleList: {}, // 退改弹窗展示数据
      showList: false, // 选仓弹出层开关
      chosenCoupon: [0, 0], // 可购仓位列表选中的下标 --- ①
      coupons: [], // 可购仓位列表 --- ①
      disabledCoupons: [], // 不可购仓位列表 --- ①
      explanationText: [], // 超标人员名字
      compliant: false, // 超标弹窗开关
      explanation: "", // 超标分类
      explanation1: "", // 超标备注
      explanationText: [],
      result: [], // 已选人员
      wrapper: false, // 遮罩层
      wrapper1: false, // 遮罩层
    };
  },
  components: {
    Insurance,
    LoadingAnimate,
    Logo,
    PeoList,
    BodyList,
    LoadingCircle,
    AircraftTicket,
    BusinessTicket,
  },
  methods: {
    // 超标填表成功
    explanFinish(explanation, explanation1) {
      this.compliant = false;
      if (explanation && explanation1) {
        this.explanation = explanation;
        this.explanation1 = explanation1;
        this.grabSeat();
      }
    },
    // 生成两种座位列表
    getSeatList() {
      let index = 0;
      this.coupons = [];
      this.disabledCoupons = [];
      let minpeo = { stand: { allowBookHighest: 999999 } };
      this.personList.forEach((e) => {
        if (parseInt(e.stand.allowBookHighest) < minpeo.stand.allowBookHighest)
          minpeo = e;
      });
      this.aircraftTicket.forEach((ticket) => {
        let couarr = [];
        let disarr = [];
        ticket.forEach((ticketArr) => {
          ticketArr.flightSeat.forEach((element) => {
            element.description = checkSeat(minpeo, element, "aircraft");
            element.condition = element.discount || " ";
            element.corp = ticketArr.corp;
            element.name =
              "余票" + (element.number < 0 ? "充足" : element.number);
            element.value = element.price.seatPrice;
            element.number != 0 ? couarr.push(element) : disarr.push(element);
          });
        });
        couarr.sort((a, b) => a.value - b.value);
        // 默认选中点击的供应商
        for (let i = 0; i < couarr.length; i++) {
          const element = couarr[i];
          if (element.corp == this.$route.query["corp" + index]) {
            this.chosenCoupon[index++] = i;
            break;
          }
        }
        this.coupons.push(couarr);
        this.disabledCoupons.push(disarr);
      });
    },
    // 选仓的钩子
    onChange(index) {
      this.showList = false;
      if (index >= 0) this.chosenCoupon[this.listindex] = index;
      // 如果余票不足
      let ticket = this.coupons[this.listindex][
        this.chosenCoupon[this.listindex]
      ];
      if (
        ticket.number > 0 &&
        parseInt(ticket.number) < this.personList.length
      ) {
        this.max = parseInt(ticket.number);
        this.$toast("余票不足");
        this.$forceUpdate();
      }
    },
    // 拉取退改签规则
    fetchRule(index) {
      this.listindex = index;
      this.$set(this.btnLoading, index, true);
      getFlightModifyAndRefundRules({
        bookInfo: this.coupons[index][this.chosenCoupon[index]].bookInfoStr,
        corp: this.coupons[index][this.chosenCoupon[index]].corp,
      })
        .then((response) => {
          this.ruleList = response.data;
          this.showRule = true;
          this.$set(this.btnLoading, index, false);
        })
        .catch((err) => {
          this.$set(this.btnLoading, index, false);
        });
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function () {
      let errorlen = 0;
      let explanationArr = [];
      if (this.result.length <= 0) return this.$toast.fail("尚未选择人员");
      for (let i = 0; i < this.result.length; i++) {
        let element = this.result[i];
        if (
          !Object.prototype.hasOwnProperty.call(element, 'tel')||
          !Object.prototype.hasOwnProperty.call(element, 'birthday')||
          !Object.prototype.hasOwnProperty.call(element, 'gender')
        )
          return this.$toast.fail("人员信息不完整");
      }
      // 行程单余额控制
      this.wrapper1 = true;
      let surplus = await this.$store.dispatch(
        "project/updateAppPrice",
        this.totalPrice
      );
      this.wrapper1 = false;
      if (this.travelInfo.isControl == "yes" && !surplus) {
        return this.$toast.fail("行程限额不足");
      }
      // 超标控制
      this.result.forEach((element) => {
        if (
          !checkSeat(
            element,
            this.coupons[0][this.chosenCoupon[0]],
            "aircraft"
          ) ||
          !checkSeat(element, this.coupons[1][this.chosenCoupon[1]], "aircraft")
        ) {
          errorlen++;
          explanationArr.push(element);
        }
      });
      if (errorlen > 0) {
        this.wrapper = false; // 关闭加载遮罩层
        this.explanationText = explanationArr; // 追加注释
        this.compliant = true; // 打开超标弹窗
      } else {
        // 变价没？
        // 去程
        let seatInfo1 = this.coupons[0][this.chosenCoupon[0]];
        let flight1 = this.aircraftTicket[0].filter((item) => {
          return item.corp == seatInfo1.corp;
        });
        flight1[0].flightSeat = [seatInfo1];
        // 返程
        let seatInfo2 = this.coupons[1][this.chosenCoupon[1]];
        let flight2 = this.aircraftTicket[1].filter((item) => {
          return item.corp == seatInfo2.corp;
        });
        flight2[0].flightSeat = [seatInfo2];
        this.wrapper1 = true;
        let flightInfo = await checkPrice({flightInfo:[flight1[0],flight2[0]]})
        this.wrapper1 = false;
        if(flightInfo.check){ // 如果变价
          this.$dialog.confirm({
            title: '提示',
            message: flightInfo.msg+'是否继续支付？',
          })
            .then(() => {
              this.grabSeat(flightInfo.flight) // 下单
            })
        }
        else this.grabSeat(flightInfo.flight)
        }
    },
    // 抢座,下完一张再下一张
    async grabSeat(flightInfo) {
      let seatInfo1 = this.coupons[0][this.chosenCoupon[0]];
      let seatInfo2 = this.coupons[1][this.chosenCoupon[1]];
      // let flight1 = this.aircraftTicket[0].filter((item) => {
      //   return item.corp == seatInfo1.corp;
      // });
      // flight1[0].flightSeat = [seatInfo1];
      this.wrapper = true;
      // 生成去程人员信息
      let personListGo = CreateTrainPeoList(
        this.result,
        seatInfo1,
        this.explanation,
        this.explanation1,
        "aircraft"
      );
      // 添加保险信息
      personListGo.forEach((element) => {
        element.AAI = 0 in this.insuranceResult[0]; // 加保险
      });
      // 请求去程
      let ticket1 = await createOrder(
        {
          flightInfo: [flightInfo[0]],
          travelUser: personListGo,
          contactPerson: this.travelInfo.loginUser,
          orderTotal: this.totalPrice.toString(),
          applyNo: this.travelInfo.appId,
        },
        this.$route.name
      );
      if (!ticket1) return (this.wrapper = false); // 如果第一张票不成功结束
      // let flight2 = this.aircraftTicket[1].filter((item) => {
      //   return item.corp == seatInfo2.corp;
      // });
      // flight2[0].flightSeat = [seatInfo2];
      // 生成返程人员信息
      let personListBack = CreateTrainPeoList(
        this.result,
        seatInfo2,
        this.explanation,
        this.explanation1,
        "aircraft"
      );
      // 添加保险信息
      personListBack.forEach((element) => {
        element.AAI = 0 in this.insuranceResult[1]; // 加保险
      });
      // 请求返程
      let ticket2 = await createOrder(
        {
          flightInfo: [flightInfo[1]],
          travelUser: personListBack,
          contactPerson: this.travelInfo.loginUser,
          orderTotal: this.totalPrice.toString(),
          applyNo: this.travelInfo.appId,
        },
        this.$route.name
      );
      this.$store.dispatch("history/setOrderItem", ticket1); // 去程订单数据
      this.wrapper = false;
      if (!ticket2) {
        this.$dialog
          .alert({
            title: "提示",
            message: "去程下单成功，返程下单失败，点击确认前往查看已下订单",
          })
          .then(() => {
            switch (this.config_gowhere) {
              case 'detail': // 转到详情
                this.$router.push({
                  path: "/paymentAircraft",
                  query: {
                    corp: ticket1.corp,
                    orderNo: ticket1.orderSerialNo,
                    outOrderNo: ticket1.outOrderNo,
                  },
                });
                break;
              default: // 默认转到订单列表
                this.$router.push({ path: "/plan" }); 
                break;
            }
          });
      } else {
        this.$store.dispatch("history/setNextOrderItem", ticket2);
        switch (this.config_gowhere) {
          case 'detail': // 转到详情
            this.$router.push({
              path: "/paymentAircraft",
              query: {
                corp: ticket1.corp,
                orderNo: ticket1.orderSerialNo,
                outOrderNo: ticket1.outOrderNo,
                cannext: true
              },
            });
            break;
          default: // 默认转到订单列表
            this.$router.push({ path: "/plan" }); 
            break;
        }
      }
    },
    // 重置选票位置
    resetPosition() {
      updataTicketDom();
    },
  },
  filters: {
    surplusTicket: function (value) {
      return value < 0 ? "充足" : value;
    },
  },
  watch: {
    // 监听开关改变title
    showList: {
      handler: function () {
        if (!this.showList) {
          window.setTitle("往返机票");
        } else if (this.listindex == 0) window.setTitle("选去程");
        else window.setTitle("选返程");
      },
    },
  },
};
</script>

<style lang="less" src="@/styles/orderCommon.less"></style>
<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less" scoped>
@import '../../styles/theme.less';
header {
  padding: 0.5rem;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
}
.headInfo {
  font-size: @font-size-lg;
  color: #000;
  text-align: left;
  background-color: #fff;
  padding: 10px 16px 0;
}
.dropdown {
  font-size: @font-size-lg;
  margin: 10px 0;
}
.ruleBtn {
  padding: 0 6px;
  min-width: 62px;
}
.dropdownIcon {
  font-size: @font-size-lg;
  transform: rotate(90deg) translateX(1px) translateY(-2px);
}
.rule {
  padding: 20px 20px 6px;
  table {
    font-size: @font-size-sm;
    .head > td {
      font-size: 13px;
      color: @black;
    }
    td {
      padding: 0 2px;
      color: @gray-7;
    }
  }
  p {
    font-size: @font-size-sm;
    color: @gray-7;
    margin: 0;
  }
}
.surplus {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 100;
  background: #fff;
}
.bottom-gap {
  height: 7.4rem;
}
</style>
<style lang="less">
.ticket .van-dropdown-menu__title {
  padding: 0 8px 0 0;
}
.ticket .wrapper {
  top: 75%;
}
.ruleBtn {
  .van-loading__spinner--circular {
    height: 14px !important;
  }
  .van-loading {
    font-size: 12px !important;
  }
}
</style>